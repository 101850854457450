<template>
  <div class="side">
    <Navigation :bgColor='bgColor' :color='color' />
    <img src="~img/50.png" class="bg-img">
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>

<script>
import Navigation from "@/components/web/Navigation.vue";
import horserace from "@/components/pc/horserace.vue";
export default {
  components: {
    Navigation,
    horserace,
  },
  data() {
    return {
      noticeColor: "#fff",
      background: "#0a9161",
      bgColor: "#add4f7",
      color: "#0a9161",
    };
  },
};
</script>


<style lang="less" scoped>
.side {
  height: 100vh;
  overflow: hidden;
  .bg-img {
    height: 100vh;
    width: 100%;
  }
}
</style>